import React, { useEffect, useState, useRef } from "react"

/** Auxiliaries */
import { Button, Container, Flex, Icon, Link, Text, Title } from "../../auxiliaries"

/** Utils */
import { CLEAR_100, DARK_900 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Locales */
import locales from "./locales/Landing.json"
import { navigate } from "gatsby"

const Landing = () => {
  const [iconName, setIconName] = useState("cloud-landing")
  const [iconLoading, setIconLoading] = useState(false)
  const device = useDeviceType()
  const lang = useLang()

  useEffect(() => {
    if (device === "mobile") {
      setIconName("cloud-landing-mobile")
      setIconLoading(true)
      setTimeout(() => {
        setIconLoading(false)
      }, 5)
    }
  }, [device])

  const ref = useRef()

  if (!device) return null

  const onClick = link => {
    const element = document.getElementById(link)

    if (ref && ref.current) {
      window.scrollTo({
        top: element.offsetTop - ref.current.offsetHeight + 300,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  return (
    <Flex
      ref={ref}
      alignItems="center"
      justifyContent="center"
      height={device === "desktop" ? "32.805556vw" : "183.574879227vw"}
    >
      <Flex direction="column">
        <Container
          delimiter={device === "mobile" && "80.6763vw"}
          direction="column"
        >
          <Title
            fontSize={device === "desktop" ? "4em" : "9.6618vw"}
            fontFamily="Raleway"
            weight="black"
            textAlign={device === "desktop" && "center"}
          >
            {locales[lang]["TITLE"]}
          </Title>
          <Text
            fontSize={device === "desktop" ? "1.111111111vw" : "3.8647343vw"}
            textAlign={device === "desktop" && "center"}
            mt={device === "desktop" ? "2.222222222vw" : "5.797101449vw"}
            mb={device === "desktop" ? "4.166666667vw" : "5.797101449vw"}
          >
            {locales[lang]["DESCRIPTION"]}
          </Text>
        </Container>
        <Flex
          justifyContent="center"
          direction={device === "mobile" ? "column" : "row"}
          gap={device === "desktop" ? "1.388888889vw" : "5.314009662vw"}
        >
          <Link href="https://enxadahost.com/members/register.php" target="_BLANK">
            <Button bgColor={DARK_900} width='100%'>
              <Text
                color={CLEAR_100}
                fontFamily="Raleway"
                fontSize={device === "desktop" ? "16px" : "3.8647343vw"}
                py={device === "mobile" && "4.347826087vw"}
                weight="bold"
              >
                {locales[lang]["CREATE_ACCOUNT"]}
              </Text>
            </Button>
          </Link>
          <Button outline onClick={() => onClick("pricing")}>
            <Text
              fontFamily="Raleway"
              fontSize={device === "desktop" ? "16px" : "3.8647343vw"}
              weight="bold"
            >
              {locales[lang]["SEE_PLANS_AND_PRICING"]}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Landing

import React, { useState } from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Button, Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import {
  CLEAR_100,
  GRAY_650,
  PURPLE_300,
  PURPLE_500,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Card from "./Card"

/** Locales */
import locales from "./locales/Card.json"

const cardsXEON = [
  {
    cpuType: "Intel® i9-13900",
    cpuClock: "24C/32T",
    cpuClockMax: "2.00GHz até 5.60GHz Turbo",
    memory: "128GB",
    memoryType: "Memória DDR5 RAM",
    transferRate: "1Gbit",
    storage: "1x 500GB",
    storageType: "NVME M2",
    value: 1700,
    url: "https://wa.me/5561991196052/?text=Vim+pela+EnxHost!",
  }, 
]

const cardsRYZEN = [
  {
    cpuType: "Ryzen™ 9 7950X",
    cpuClock: "16C/32T",
    cpuClockMax: "3.7GHz até 4.6GHz",
    memory: "128GB",
    memoryType: "Memória DDR5 RAM",
    transferRate: "10Gbit",
    storage: "1x 1TB",
    storageType: "NVME M2",
    value: 2600,
    url: "https://wa.me/5561991196052/?text=Vim+pela+EnxHost!",
  },
  {
    cpuType: "Ryzen™ 9 5900X",
    cpuClock: "12C/24T",
    cpuClockMax: "3.7GHz até 4.8GHz",
    memory: "128GB",
    memoryType: "Memória DDR4 RAM",
    transferRate: "10Gbit",
    storage: "1x 1TB",
    storageType: "NVME M2",
    value: 1600,
    url: "https://wa.me/5561991196052/?text=Vim+pela+EnxHost!",
  },
  {
    cpuType: "Ryzen 7 5700G",
    cpuClock: "8C/16T",
    cpuClockMax: "3.8GHz até 4.6GHz",
    memory: "128GB",
    memoryType: "Memória DDR4 RAM",
    transferRate: "10Gbit",
    storage: "1x 1TB",
    storageType: "NVME M2",
    value: 1400,
    url: "https://wa.me/5561991196052/?text=Vim+pela+EnxHost!",
  }, 
  {
    cpuType: "Ryzen™ 5 5600",
    cpuClock: "6C/12T",
    cpuClockMax: "3.5GHz até 4.4GHz",
    memory: "128GB",
    memoryType: "Memória DDR4 RAM",
    transferRate: "10Gbit",
    storage: "1x 1TB",
    storageType: "NVME M2",
    value: 1200,
    url: "https://wa.me/5561991196052/?text=Vim+pela+EnxHost!",
  },
]

const Pricing = () => {
  const device = useDeviceType()
  const lang = useLang()

  const [plans, setPlans] = useState("VPS_RYZEN")

  if (!device) return null

  return (
    <Flex
      id="pricing"
      direction="column"
      alignItems="center"
      mt={device === "desktop" ? "13.888888889vw" : "50.241545894vw"}
      mb={device === "desktop" ? "11.111111111vw" : "23.429951691vw"}
    >
      <Container
        delimiter={device === "desktop" ? "49.097222222vw" : "80.676328502vw"}
      >
        <Title
          fontSize={device === "desktop" ? "4em" : "9.6618vw"}
          fontFamily="Raleway"
          weight="black"
          textAlign={device === "desktop" && "center"}
          mb={device === "desktop" ? "2.2222vw" : "5.7971vw"}
        >
          {locales[lang]["TITLE"]}
        </Title>
      </Container>
      <Container
        delimiter={device === "mobile" && "80.676328502vw"}
        justifyContent={device === "desktop" && "center"}
      >
        <Text
          color={GRAY_650}
          fontSize={device === "desktop" ? "1.111111111vw" : "3.8647vw"}
          textAlign={device === "desktop" && "center"}
          mb={device === "desktop" ? "4.1667vw" : "15.458937198vw"}
        >
          {locales[lang]["DESCRIPTION"]}
        </Text>
      </Container>
      <Container
        delimiter={device === "mobile" && "80.676328502vw"}
        justifyContent={device === "desktop" && "center"}
        mb={device === "desktop" ? "4.1667vw" : "15.458937198vw"}
      >
        <Button
          bgColor={plans === "VPS_RYZEN" ? PURPLE_500 : PURPLE_300}
          px={device === "mobile" && "5.7971vw"}
          py={device === "mobile" && "4.347826087vw"}
          mr={"50px"}
          onClick={() => setPlans("VPS_RYZEN")}
        >
          <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "3.8647vw" : "16px"}
            weight="bold"
            color={plans === "VPS_RYZEN" ? "#000" : CLEAR_100}
          >
            {locales[lang]["VPS_RYZEN"]}
          </Text>
        </Button>
        <Button
          bgColor={plans === "VPS_XEON" ? PURPLE_500 : PURPLE_300}
          px={device === "mobile" && "5.7971vw"}
          py={device === "mobile" && "4.347826087vw"}
          onClick={() => setPlans("VPS_XEON")}
        >
          <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "3.8647vw" : "16px"}
            color={plans === "VPS_XEON" ? "#000" : CLEAR_100}
            weight="bold"
          >
            {locales[lang]["VPS_XEON"]}
          </Text>
        </Button>
      </Container>
      <CardGrid device={device}>
        {plans === "VPS_XEON"
          ? cardsXEON.map(card => <Card key={card} {...card} lang={lang} />)
          : cardsRYZEN.map(card => <Card key={card} {...card} lang={lang} />)}
      </CardGrid>
    </Flex>
  )
}

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: "1fr";
  gap: ${({ device }) => (device === "desktop" ? "1.1667vw" : "5.7971vw")};
`

export default Pricing

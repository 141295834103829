import React from "react"

/** Auxiliaries */
import { Image } from "../../auxiliaries"

import { useDeviceType } from "../../../utils/layout/LayoutContext"

const Card = ({ name, icon }) => {
  const device = useDeviceType()

  if (!device) return null

  return (
    <Image
      src={icon}
      width={device === "desktop" ? "8em" : "2em"}
      userSelect={"none"}
      onDragStart={event => event.preventDefault()}
      style={{
        filter: "grayscale(1)",
      }}
      alt={name}
    />
  )
}

export default Card
